
import { ElMessageBox } from "element-plus";
import { defineComponent, reactive } from "vue";

export default defineComponent({
  name: "file",
  props: {
    folder: Object,
    listMode: Boolean,
    perm: Boolean,
  },
  emit: [],
  components: {},
  setup(props, {emit}){

    const state = reactive({
      loading: false,
      countLoad: 0,
      popVisible: false,
    })

    const clickFolder = (e) => {
      e.preventDefault();
      state.popVisible = false;
      state.loading = true;
      emit('clickFolder', props.folder?.gfo_seq)
    }

    const rightClickFolder = (e) => {
      if (props.perm || props.folder?.gfo_knum_employe) {
        e.preventDefault();
        state.popVisible = true;
      }
    }

    const renameFolder = () => {
      ElMessageBox.prompt('Entrez le nouveau nom', 'Renommer', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Annuler',
        inputValue: props.folder?.gfo_label,
        // inputPattern:
        // inputErrorMessage: 'Invalid Email',
      })
        .then(async({ value }) => {
          emit("renameFolder", value, props.folder?.gfo_seq)
        })
    }

    const deleteFolder = () => {
      ElMessageBox.prompt('Écrivez \'SUPPRIMER\' pour confirmer', 'Supprimer', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Annuler',
        inputPattern: /\bSUPPRIMER\b/,
        inputErrorMessage: ' ',
      })
      .then(async() => {
        emit("deleteFolder", props.folder?.gfo_seq, 'folder')
      })
    }

    const getContainerClasses = () => {
      const padding = window.matchMedia('(max-width: 767px)').matches ? 'p-0' : 'p-8';
      if (props.listMode) {
        return 'gap-3';
      }else{
        return 'justify-content-center text-center flex-column ' + padding;
      }
    }
    const getPopPlacement = () => {
      if (window.matchMedia('(max-width: 768px)').matches) {
        return 'bottom'
      }else{
        return 'right'
      }
    }

    return{
      clickFolder,
      rightClickFolder,
      getContainerClasses,
      state,
      getPopPlacement,
      renameFolder,
      deleteFolder,
    }
  }
});
