<template>
 <el-popover
    ref="popover"
    :placement="getPopPlacement()"
    :width="200"
    trigger="focus"
    :visible="state.popVisible"
  >
    <div @click="renameFolder()" class="btn-sm btn-primary cursor-pointer text-white ps-3"><i class="bi bi-pencil-fill me-2 text-white"></i>Renommer</div>
    <div v-if="perm" @click="deleteFolder()" class="btn-sm btn-danger cursor-pointer text-white mt-2 ps-3"><i class="bi bi-trash3-fill me-2 text-white"></i>Supprimer</div>
    <template #reference>
      <el-button class="border-0 m-0 p-4 folderfile cursor-pointer w-100 h-100 bg-transparent" v-loading="state.loading" @click="clickFolder" @contextmenu="rightClickFolder" v-on:blur="() => state.popVisible = false">
          <div class="p-0 d-flex text-break h-100" :class="getContainerClasses()">
            <div class="text-gray-800 text-hover-primary d-flex cursor-pointer text-break position-relative" :class="{'flex-column': !listMode, 'align-items-center': listMode}">
              <div class="symbol" :class="{'symbol-75px mb-6': !listMode, 'symbol-25px me-3': listMode}">
                <img :draggable="false" :src="'media/svg/files/folder-document' + (folder.gfo_knum_employe ? '-private' : '') +'.svg'" alt="" />
              </div>
              <div class="fs-7 fw-bolder doted-lines-text text-wrap" :class="{'d-flex align-items-center text-start': listMode}">{{folder.gfo_label}}</div>
              <div class="d-flex align-items-center justify-content-center top-0" :class="{'position-absolute w-100 mt-9': !listMode}" v-if="folder.totalfiles > 0">
                <span :class="{'position-absolute': !listMode, 'ms-1': listMode}">{{listMode ? `(${folder.totalfiles})` : folder.totalfiles}}</span>
                <i v-if="!listMode" class="bi bi-file-earmark-fill fs-1 text-white" style="color: white !important;"></i>
              </div>
            </div>
            <div v-if="folder.nomprenom" class="fs-7 fw-bold text-gray-400 align-self-center" :class="{'ms-auto': listMode}">{{folder.nomprenom}}</div>
          </div>
      </el-button>
    </template>
 </el-popover>
</template>

<script lang="ts">
import { ElMessageBox } from "element-plus";
import { defineComponent, reactive } from "vue";

export default defineComponent({
  name: "file",
  props: {
    folder: Object,
    listMode: Boolean,
    perm: Boolean,
  },
  emit: [],
  components: {},
  setup(props, {emit}){

    const state = reactive({
      loading: false,
      countLoad: 0,
      popVisible: false,
    })

    const clickFolder = (e) => {
      e.preventDefault();
      state.popVisible = false;
      state.loading = true;
      emit('clickFolder', props.folder?.gfo_seq)
    }

    const rightClickFolder = (e) => {
      if (props.perm || props.folder?.gfo_knum_employe) {
        e.preventDefault();
        state.popVisible = true;
      }
    }

    const renameFolder = () => {
      ElMessageBox.prompt('Entrez le nouveau nom', 'Renommer', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Annuler',
        inputValue: props.folder?.gfo_label,
        // inputPattern:
        // inputErrorMessage: 'Invalid Email',
      })
        .then(async({ value }) => {
          emit("renameFolder", value, props.folder?.gfo_seq)
        })
    }

    const deleteFolder = () => {
      ElMessageBox.prompt('Écrivez \'SUPPRIMER\' pour confirmer', 'Supprimer', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Annuler',
        inputPattern: /\bSUPPRIMER\b/,
        inputErrorMessage: ' ',
      })
      .then(async() => {
        emit("deleteFolder", props.folder?.gfo_seq, 'folder')
      })
    }

    const getContainerClasses = () => {
      const padding = window.matchMedia('(max-width: 767px)').matches ? 'p-0' : 'p-8';
      if (props.listMode) {
        return 'gap-3';
      }else{
        return 'justify-content-center text-center flex-column ' + padding;
      }
    }
    const getPopPlacement = () => {
      if (window.matchMedia('(max-width: 768px)').matches) {
        return 'bottom'
      }else{
        return 'right'
      }
    }

    return{
      clickFolder,
      rightClickFolder,
      getContainerClasses,
      state,
      getPopPlacement,
      renameFolder,
      deleteFolder,
    }
  }
});
</script>
