<template>
 <el-popover
    ref="popover"
    :placement="getPopPlacement()"
    :width="200"
    trigger="focus"
    :visible="state.popVisible"
  >
    <div @click="renameFile()" class="btn-sm btn-primary cursor-pointer text-white ps-3"><i class="bi bi-pencil-fill me-2 text-white"></i>Renommer</div>
    <div v-if="perm" @click="deleteFile()" class="btn-sm btn-danger cursor-pointer text-white mt-2 ps-3"><i class="bi bi-trash3-fill me-2 text-white"></i>Supprimer</div>
    <template #reference>
      <el-button class="border-0 m-0 p-0 folderfile cursor-pointer w-100 h-100 bg-transparent" v-loading="state.loading" @click="clickFile" @contextmenu="rightClickFile" v-on:blur="() => state.popVisible = false">
        <div class="p-4 folderfile cursor-pointer" @click="clickFile()" v-loading="state.loading">
          <div class="d-flex h-100 " :class="getContainerClasses()">
            <div class="text-gray-800 text-hover-primary d-flex cursor-pointer h-100 text-break" :class="{'flex-column': !listMode, 'align-items-center gap-3': listMode}">
              <div class="symbol" :class="{'symbol-60px mb-6 mt-5': !listMode, 'symbol-25px': listMode}">
                <img :draggable="false" :src="getIcon(file.filetype)" alt="" />
                <img v-if="file.ged_knum_employe > 0" :class="{'cadenas-file-bloc': !listMode, 'cadenas-file-list': listMode}" src="media/svg/files/cadenas.svg" alt="">
              </div>
              <div class="fs-7 fw-bolder doted-lines-text text-wrap" :class="{'mb-2': !listMode, 'd-flex align-items-center text-start': listMode}">{{ file.ged_title }}</div>
            </div>
            <div v-if="file.nomprenom" class="fs-7 fw-bold text-gray-400" :class="{'d-flex align-items-center ms-auto me-3': listMode}">{{file.nomprenom}}</div>
            <div class="fs-7 fw-bold text-gray-400 align-self-center">{{ formatDate(file.ged_d_created) }}</div>
          </div>
        </div>
      </el-button>
    </template>
 </el-popover>
</template>

<script lang="ts">
import { defineComponent, reactive } from "vue";
import * as moment from "moment"
import { ElMessageBox } from "element-plus";

export default defineComponent({
  name: "file",
  props: {
    file: Object,
    listMode: Boolean,
    perm: Boolean,
  },
  components: {},
  setup(props, {emit}){
    const valableFiles = ["all-svg", "ai", "css", "doc", "folder-document", "pdf", "sql", "tif", "upload", "xml", "jpg", "jpeg", "bmp", "png", "gif", "svg", "tiff", "webp"]
    const imagesFiles = ["jpg", "jpeg", "bmp", "png", "gif", "svg", "tiff", "webp"]

    const state = reactive({
      popVisible: false,
      loading: false
    })

    const getIcon = (icon) => {
      if (props.file?.fileImg) {
        return props.file.fileImg
      }
      if (!(valableFiles.indexOf(props.file?.filetype as any) > -1)) {
        return "media/svg/files/all-svg.svg"
      }else{
        if (imagesFiles.indexOf(props.file?.filetype)>-1) {
        return "media/svg/files/img.svg"
        }
        return `media/svg/files/${icon}.svg`
      }
    }

    const clickFile = (e) => {
      e.preventDefault();
      state.popVisible = false;
      state.loading = true;
      emit('clickFile', props.file)
    }

    const rightClickFile = (e) => {
      if (props.perm || props.file?.ged_knum_employe > 0) {
        e.preventDefault();
        state.popVisible = true;
      }
    }

    const renameFile = () => {
      ElMessageBox.prompt('Entrez le nouveau nom', 'Renommer', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Annuler',
        inputValue: props.file?.ged_title,
        // inputPattern:
        // inputErrorMessage: 'Invalid Email',
      })
        .then(async({ value }) => {
          state.loading = true;
          await emit("renameFile", value, props.file?.ged_seq)
          state.loading = false;
        })
    }

    const deleteFile = () => {
      ElMessageBox.prompt('Écrivez \'SUPPRIMER\' pour confirmer', 'Supprimer', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Annuler',
        inputPattern: /\bSUPPRIMER\b/,
        inputErrorMessage: ' ',
      })
      .then(async() => {
        state.loading = true;
        await emit("deleteFile", props.file?.ged_seq, 'file')
        state.loading = false;
      })
    }

    const getContainerClasses = () => {
      const padding = window.matchMedia('(max-width: 767px)').matches ? 'p-0' : 'p-8';
      if (props.listMode) {
        return 'justify-content-between gap-3';
      }else{
        return 'justify-content-center text-center flex-column ' + padding;
      }
    }

    const getPopPlacement = () => {
      if (window.matchMedia('(max-width: 768px)').matches) {
        return 'bottom'
      }else{
        return 'right'
      }
    }

    const formatDate = (d) => {
      return moment.default(d).format("D MMM YYYY");
    };

    return {
      getIcon,
      clickFile,
      formatDate,
      getContainerClasses,
      getPopPlacement,
      renameFile,
      rightClickFile,
      deleteFile,
      state
    }
  }
});
</script>
