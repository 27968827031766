
import { defineComponent, reactive } from "vue";
import * as moment from "moment"
import { ElMessageBox } from "element-plus";

export default defineComponent({
  name: "file",
  props: {
    file: Object,
    listMode: Boolean,
    perm: Boolean,
  },
  components: {},
  setup(props, {emit}){
    const valableFiles = ["all-svg", "ai", "css", "doc", "folder-document", "pdf", "sql", "tif", "upload", "xml", "jpg", "jpeg", "bmp", "png", "gif", "svg", "tiff", "webp"]
    const imagesFiles = ["jpg", "jpeg", "bmp", "png", "gif", "svg", "tiff", "webp"]

    const state = reactive({
      popVisible: false,
      loading: false
    })

    const getIcon = (icon) => {
      if (props.file?.fileImg) {
        return props.file.fileImg
      }
      if (!(valableFiles.indexOf(props.file?.filetype as any) > -1)) {
        return "media/svg/files/all-svg.svg"
      }else{
        if (imagesFiles.indexOf(props.file?.filetype)>-1) {
        return "media/svg/files/img.svg"
        }
        return `media/svg/files/${icon}.svg`
      }
    }

    const clickFile = (e) => {
      e.preventDefault();
      state.popVisible = false;
      state.loading = true;
      emit('clickFile', props.file)
    }

    const rightClickFile = (e) => {
      if (props.perm || props.file?.ged_knum_employe > 0) {
        e.preventDefault();
        state.popVisible = true;
      }
    }

    const renameFile = () => {
      ElMessageBox.prompt('Entrez le nouveau nom', 'Renommer', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Annuler',
        inputValue: props.file?.ged_title,
        // inputPattern:
        // inputErrorMessage: 'Invalid Email',
      })
        .then(async({ value }) => {
          state.loading = true;
          await emit("renameFile", value, props.file?.ged_seq)
          state.loading = false;
        })
    }

    const deleteFile = () => {
      ElMessageBox.prompt('Écrivez \'SUPPRIMER\' pour confirmer', 'Supprimer', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Annuler',
        inputPattern: /\bSUPPRIMER\b/,
        inputErrorMessage: ' ',
      })
      .then(async() => {
        state.loading = true;
        await emit("deleteFile", props.file?.ged_seq, 'file')
        state.loading = false;
      })
    }

    const getContainerClasses = () => {
      const padding = window.matchMedia('(max-width: 767px)').matches ? 'p-0' : 'p-8';
      if (props.listMode) {
        return 'justify-content-between gap-3';
      }else{
        return 'justify-content-center text-center flex-column ' + padding;
      }
    }

    const getPopPlacement = () => {
      if (window.matchMedia('(max-width: 768px)').matches) {
        return 'bottom'
      }else{
        return 'right'
      }
    }

    const formatDate = (d) => {
      return moment.default(d).format("D MMM YYYY");
    };

    return {
      getIcon,
      clickFile,
      formatDate,
      getContainerClasses,
      getPopPlacement,
      renameFile,
      rightClickFile,
      deleteFile,
      state
    }
  }
});
