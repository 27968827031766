
import { defineComponent, h, onMounted, reactive } from "vue";
import KTFolder from "@/views/GED/Folder.vue";
import KTFile from "@/views/GED/File.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import mAxiosApi from "@/api";
import { ElMessageBox, ElNotification } from "element-plus";
import VueEasyLightbox from 'vue-easy-lightbox';
import {FileService} from "@/core/services/FileService";

export default defineComponent({
  name: "documents",
  components: {
    KTFolder,
    KTFile,
    VueEasyLightbox
  },
  setup() {
    const GEDfileService = new FileService("ged")
    const state = reactive({
      tree: [] as any,
      folders: [] as any,
      loading: true,
      loadingUpload: false,
      loadingRow: false,
      renderFile: 0,
      currentFolder: {} as any,
      displayedGedGroup: [],
      drawerDoc: false,
      sourcePDF: "",
      mDoc: {} as any,
      listMode: window.matchMedia('(max-width: 767px)').matches,
      renderTree: 0,
      showImage: false,
      newFolder: {
        name: "",
        isPrivate: true,
      },
      foldersPerm: false,
      optionsPeople: [] as any,
      fichierPour: 0,
      sendMail: false,
      uploadedFiles: [] as any,
      viewAll: false,
      loadingSeeAll: false,
    })

    const getTreeChilds = (seq, parentChilds) =>{
      const childsList = state.folders.filter(el => el.gfo_knum_parent == seq);
      childsList.forEach(element => {
        const data = {
          gfo_label: element.gfo_label,
          ged_title: element.ged_title,
          label: element.gfo_label + (element.totalfiles>0 ?' (' + element.totalfiles + ')' : ''),
          totalfiles: element.totalfiles,
          gfo_seq: element.gfo_seq,
          gfo_knum_parent: element.gfo_knum_parent,
          key: 3,
          path: element.path,
          children: []
        };
        if (!data.gfo_knum_parent) {
          data.gfo_knum_parent = 0
        }
        parentChilds.push(data)
        getTreeChilds(element.gfo_seq, data.children);
      })
    };

    const clickFolder = async(folder) => {
      const folderContent = folder.gfo_seq ? await mAxiosApi.prototype.getAxios("/getContentsFolder/" + folder.gfo_seq + (!state.viewAll ? "/0" : "/1")) : await mAxiosApi.prototype.getAxios("/getContentsFolder" + "/0" + (!state.viewAll ? "/0" : "/1"));
      folderContent.resultFolders.forEach(folder => {
        folder.totalfiles = state.folders.find(element => element.gfo_seq == folder.gfo_seq).totalfiles
      });
      state.displayedGedGroup = [...folderContent.resultFolders, ...folderContent.resultGED] as any;
      state.renderTree = state.renderTree + 1;
      state.currentFolder = folder;
      state.loadingRow = false;
    }

    const clickMainFolder = (seq) => {
      if (!seq) {
        clickFolder({
          path: null,
          gfo_seq: null,
        })
        return
      }
      const goodFolder = state.folders.filter(el => el.gfo_seq == seq);
      clickFolder(goodFolder[0]);
    }

    function dataURLtoBlob(dataurl) {
      var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
      while(n--){
          u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], {type:mime});
    }

    const clickFile = async (file) => {
      const fileData = await GEDfileService.getGedFile(file.ged_seq) as any;
      state.mDoc = fileData;
      state.mDoc.filetype = file.filetype;
      let typeMime;
      const acceptedDrawer = ["jpg", "jpeg", "bmp", "png", "gif", "svg", "tiff", "webp", "pdf"];

      if (file.filetype == "jpg") file.filetype = "jpeg";
      if (acceptedDrawer.indexOf(file.filetype) > -1 && window.matchMedia('(min-width: 768px)').matches){
        let srcPDF
        if (file.filetype == "pdf"){
          typeMime = "application/pdf";
          srcPDF = "data:"+ typeMime+";base64," + fileData.ged_document;
          srcPDF = dataURLtoBlob(srcPDF)
          srcPDF = window.URL.createObjectURL(srcPDF)
        }else{
          typeMime = "image/"+file.filetype;
          srcPDF = "data:"+ typeMime+";base64," + fileData.ged_document;
        }
        state.sourcePDF = srcPDF;
        file.filetype == "pdf" ? state.drawerDoc = true : state.showImage = true;
      } else {
        downloadFile();
      }
      state.renderFile = state.renderFile +1;
    }

    const downloadFile = () => {
      const linkSource = `data:application/msg;base64,${state.mDoc.ged_document}`;
      const downloadLink = document.createElement("a");
      downloadLink.href = linkSource;
      downloadLink.download = state.mDoc.ged_title;
      downloadLink.click();
    }

    const getPeopleSelect = () => {
      const options = [] as any;
      state.optionsPeople.forEach(element => {
        options.push(h("option", {value: element.emp_seq, selected: element.isme}, element.emp_nomprenom))
      });
      return options
    }

    const toggleSelectPpl = (display) => {
      const selectPpl = document.querySelector(".select-ppl") as any;
      const preventMail = document.querySelector(".prevent-mail") as any;
      const sendSwitch = document.querySelector(".send-switch") as any;
      selectPpl.style.display = display;
      if (display == "none") {
        preventMail.style.height = "0";
        preventMail.style.opacity = "0";
        preventMail.style.pointerEvents = "none";
        state.sendMail = false;
        sendSwitch.checked = false;
      }else{
        preventMail.style.height = "";
        preventMail.style.opacity = "";
        preventMail.style.pointerEvents = "";
      }
    }
    
    let refreshCount = 0
    const uploadFile = async (file) => {
      if (refreshCount == 0 && state.foldersPerm && !state.currentFolder.gfo_knum_employe) {
        ElMessageBox({
          message: h("div", null, [
            h("h4", "Pour qui est ce fichier ?"),
            h("p", "Séléctionnez la personne concernée"),
            h("div", {class: ["d-flex flex-column mt-5"]}, [
              h("div", {class: ["d-flex align-items-center gap-3"]}, [
                h("input", {type: "radio", name: "private", checked: false, class: "form-check-input me-1", 'onChange': () => {state.fichierPour = -1; toggleSelectPpl("none")}}),
                h("label", {class: "col-form-label fw-semobold fs-6"}, "Pour tout le monde"),
              ]),
              h("div", {class: ["d-flex align-items-center gap-3"]}, [
                h("input", {type: "radio", name: "private", checked: true, class: "form-check-input me-1", 'onChange': () => {state.fichierPour = 0; toggleSelectPpl("initial")}}),
                h("label", {class: "col-form-label fw-semobold fs-6"}, "Une personne spécifique"),
              ]),
            ]),
            state.fichierPour >= 0 && h("select", {class: "form-select ps-4 mt-4 w-80 select-ppl", 'onChange': (e) => {state.fichierPour = e.target.value}}, getPeopleSelect()),
            h("div", {class: "form-check form-switch d-flex align-items-center p-0 mt-3 ms-1 prevent-mail"}, [
              h("label", {class: "col-form-label fw-semobold fs-6"}, "Prévenir par mail"),
              h("input", {type: "checkbox", name: "private", class: "form-check-input ms-3 send-switch", "onChange": () => {state.sendMail = !state.sendMail}}),
            ]),
          ]),
          inputValidator: () => {return true},
          confirmButtonText: 'OK',
          cancelButtonText: 'Annuler',
          customClass: "responsiveMessageBox",
          showCancelButton: true,
          callback: (action) => {
            if (action != "cancel") {
              state.loadingUpload = true;
              state.uploadedFiles.forEach(async (uploaded) => {
                await GEDfileService.uploadFile(state.currentFolder.gfo_seq, uploaded, null, state.fichierPour, state.sendMail);
                if (state.uploadedFiles.length -1 == state.uploadedFiles.indexOf(uploaded)) {
                  await getInitialData();
                  clickMainFolder(state.currentFolder.gfo_seq);
                }
                state.loadingUpload = false;
              });
            }
            state.uploadedFiles = [];
            refreshCount = 0;
            state.fichierPour = 0;
            state.sendMail = false;
          }
        })
        refreshCount = refreshCount + 1
      }else if(state.foldersPerm && state.currentFolder.gfo_knum_employe && (state.optionsPeople.find(el => el.isme == 1).emp_seq != state.currentFolder.gfo_knum_employe)){
        if (state.foldersPerm && refreshCount == 0) {
          refreshCount = refreshCount + 1;
          ElMessageBox.confirm(
            'Voulez vous prévenir l\'utilisateur par mail ?',
            'Prévenir la personne concernée ?',
            {
              confirmButtonText: 'Oui',
              cancelButtonText: 'Non',
              callback: (action) => {
                if (action != "cancel") {
                  state.sendMail = true;
                }else{
                  state.sendMail = false;
                }
                state.loadingUpload = true;
                state.uploadedFiles.forEach(async (uploaded) => {
                  await GEDfileService.uploadFile(state.currentFolder.gfo_seq, uploaded, null, state.currentFolder.gfo_knum_employe, state.sendMail);
                  if (state.uploadedFiles.length -1 == state.uploadedFiles.indexOf(uploaded)) {
                    state.uploadedFiles = [];
                    await getInitialData();
                    clickMainFolder(state.currentFolder.gfo_seq);
                    refreshCount = 0;
                    state.loadingUpload = false;
                    state.sendMail = false;
                  }
                })
              }
            }
          )
        }
      }else if(!state.foldersPerm || (state.foldersPerm && state.optionsPeople.find(el => el.isme == 1).emp_seq == state.currentFolder.gfo_knum_employe)){
        state.loadingUpload = true;
        refreshCount = refreshCount + 1;
        const lastCount = refreshCount;
        await GEDfileService.uploadFile(state.currentFolder.gfo_seq, file.raw, null, state.currentFolder.gfo_knum_employe);
        setTimeout(async () => {
          if (refreshCount == lastCount) {
            state.uploadedFiles = [];
            await getInitialData();
            clickMainFolder(state.currentFolder.gfo_seq);
            refreshCount = 0;
            state.loadingUpload = false;
          }
        }, 1000);
      }
      state.uploadedFiles.push(file.raw)
    }

    const createFolder = () => {
      ElMessageBox({
        message: h("div", null, [
          h("h4", "Créer un dossier"),
          h("p", "Entrez le nom du nouveau dossier"),
          h("input", {type: "text", class: "form-control ps-4 mt-4 w-80", 'onChange': (e) => {state.newFolder.name = e.target.value}}),
          state.foldersPerm && !state.currentFolder.gfo_knum_employe && h("div", {class: ["d-flex flex-column mt-5"]}, [
            h("div", {class: ["d-flex align-items-center gap-3"]}, [
              h("input", {type: "radio", name: "private", checked: true, class: "form-check-input me-1", 'onChange': (e) => {state.newFolder.isPrivate = e.target.checked}}),
              h("label", {class: "col-form-label fw-semobold fs-6"}, "Privé"),
            ]),
            h("div", {class: ["d-flex align-items-center gap-3"]}, [
              h("input", {type: "radio", name: "private", checked: false, class: "form-check-input me-1", 'onChange': (e) => {state.newFolder.isPrivate = !e.target.checked}}),
              h("label", {class: "col-form-label fw-semobold fs-6"}, "Public"),
            ]),
          ])
        ]),
        inputValidator: () => {return true},
        confirmButtonText: 'OK',
        cancelButtonText: 'Annuler',
        customClass: "responsiveMessageBox",
        showCancelButton: true,
        inputErrorMessage: 'valeur incorrect',
      })
      .then(async() => {
        if (state.newFolder.name) {
          state.loadingUpload = true;
          const folderExists = await GEDfileService.createFolder(state.newFolder.name, state.currentFolder.gfo_seq, state.newFolder.isPrivate, state.currentFolder.gfo_knum_employe);
          if (folderExists) {
            ElNotification({
              title: 'Attention',
              message: 'Ce dossier existe déjà !',
              type: 'error',
              duration: 3000
            })
          }
          await getInitialData();
          clickMainFolder(state.currentFolder.gfo_seq);
          state.newFolder.name = "";
          state.newFolder.isPrivate = true;
          state.loadingUpload = false;
        }
      })
    }

    const renameFolder = async (newName, gfo_seq) => {
      const data = {
        gfo_label: newName,
        gfo_seq: gfo_seq
      }
      const response = await mAxiosApi.post("/renameFolder", data);
      if (response.data.exists == 1) {
        ElNotification({
          title: 'Attention',
          message: 'Un dossier du même nom existe déjà !',
          type: 'error',
          duration: 3000
        })
      }else{
        await getInitialData();
        clickMainFolder(state.currentFolder.gfo_seq);
      }
    }

    const renameFile = async (newName, ged_seq) => {
      const data = {
        ged_title: newName,
        ged_seq: ged_seq
      }
      const response = await mAxiosApi.post("/renameFile", data);
      if (response.data.exists == 1) {
        ElNotification({
          title: 'Attention',
          message: 'Un fichier du même nom existe déjà !',
          type: 'error',
          duration: 3000
        })
      }else{
        await getInitialData();
        clickMainFolder(state.currentFolder.gfo_seq);
      }
    }

    const deleteFileFolder = async (seq, type) => {
      if (type == 'file') {
        const idRes = await mAxiosApi.prototype.getAxios("/getCodeId/" + seq)
        console.log(idRes)
        const data = {
          ged_seq: seq,
          code: idRes.results
        }
        await mAxiosApi.post("/deleteFile", data);
      }else{
        const data = {
          gfo_seq: seq,
        }
        const response = await mAxiosApi.post("/deleteFolder", data);
        if (response.data.notEmpty) {
          ElNotification({
            title: 'Attention',
            message: 'Votre dossier n\'est pas vide !',
            type: 'error',
            duration: 3000
          });
        }
      }
      await getInitialData();
      clickMainFolder(state.currentFolder.gfo_seq);
    }

    const getPathSplit = () => {
      if (!state.currentFolder.path) {
        return ['Vos documents']
      }
      if (state.currentFolder.path.indexOf(" > ") > -1) {
        return state.currentFolder.path.split(' > ')
      }else{
        return [state.currentFolder.path]
      }
    }

    const getInitialData = async() => {
      const folders = await mAxiosApi.prototype.getAxios("/getFolders" + (!state.viewAll ? "/0" : "/1"));
      state.folders = folders.results;
      const initialFolder = [];
      getTreeChilds(null, initialFolder);
      state.tree = initialFolder;
      state.renderTree = state.renderTree + 1;
    }

    const preventLimit = () => {
      ElNotification({
        title: 'Attention',
        message: 'Vous ne pouvez pas dépasser les 40 fichiers !',
        type: 'error',
        duration: 3000
      });
    }

    const handleSeeAll = async (change) => {
      if (state.viewAll == change) return false;
      state.loadingSeeAll = true;
      state.viewAll = change;
      await getInitialData();
      if (change && state.currentFolder.gfo_knum_employe) {
        clickMainFolder(0);
      }else{
        clickMainFolder(state.currentFolder.gfo_seq);
      }
      state.loadingSeeAll = false;
    }

    onMounted(async () => {
      const foldersPerm = await mAxiosApi.prototype.getAxios("/getFolderPerm");
      state.foldersPerm = foldersPerm.results;
      if (foldersPerm.results) {
        const pplList = await mAxiosApi.prototype.getAxios("/getAllUsers");
        state.optionsPeople = pplList.results;
      }
      await getInitialData();
      const folderContent = await mAxiosApi.prototype.getAxios("/getContentsFolder");
      folderContent.resultFolders.forEach(folder => {
        folder.totalfiles = state.folders.find(element => element.gfo_seq == folder.gfo_seq).totalfiles
      });
      state.displayedGedGroup = [...folderContent.resultFolders, ...folderContent.resultGED] as any;
      setCurrentPageBreadcrumbs("Documents", ["Pages", "Profile"]);
      state.loading = false
    });
    return{
      state,
      clickFolder,
      clickMainFolder,
      clickFile,
      getPathSplit,
      downloadFile,
      uploadFile,
      createFolder,
      preventLimit,
      handleSeeAll,
      renameFolder,
      renameFile,
      deleteFileFolder,
    }
  },
});
